import { UseGtmInterface, UseGtmPurchasesParams } from './useGtm';
import { useRouter, useContext } from '@nuxtjs/composition-api';
import { useMagentoConfiguration } from '~/composables';
import { Product } from '~/modules/catalog/product/types';

// Create the datalayer
const initDataLayer = () => {
  if (process.client) {
    window['dataLayer'] = window['dataLayer'] || [];
  }
};

// Push to the GTM datalayer
const pushToDataLayer = (event: Object) => {
  if (process.client) {
    if (!window['dataLayer']) {
      initDataLayer();
    }
    window['dataLayer'].push({ ecommerce: null }); // Clear the previous ecommerce object.
    window['dataLayer'].push(event);
  }
};

// Convert product list data into GTM format
function convertProductListToGTM(products: Array<Product>, category?: string) {
  const items = products.map((product) => ({
    name: product.name,
    id: product.sku,
    price: product.price_range.minimum_price.final_price.value,
    category: category,
  }));

  return items;
}

// Convert product detail data into GTM format
function convertProductToGTM(product: Product, quantity?: Number) {
  let item = {
    name: product.name,
    id: product.sku,
    price: product.price_range.minimum_price.final_price.value,
  };

  if (product.categories) {
    product.categories.forEach(function (value, i) {
      item['category' + (i > 0 ? i : '')] = value.name;
    });
  }

  const variantSku = product?.configurable_product_options_selection?.variant?.sku;
  if (variantSku) {
    item['variant'] = product.configurable_product_options_selection.variant.sku;
  }

  if (quantity) {
    item['quantity'] = quantity;
  }

  return item;
}

// Convert checkout products into GTM format
function convertCheckoutProductsToGTM(products: Array<any>) {
  let items = [];

  products.forEach((item) => {
    let product = {
      name: item.product.name,
      id: item.product.sku,
      price: item.product.price_range.minimum_price.final_price.value,
      quantity: item.quantity,
    };

    if (item.product.categories) {
      item.product.categories.forEach(function (value, i) {
        product['category' + (i > 0 ? i : '')] = value.name;
      });
    }

    const variantSku = item.configured_variant?.sku;
    if (variantSku) {
      product['variant'] = item.configured_variant?.sku;
    }

    items.push(product);
  });

  return items;
}

export function useGtm(): UseGtmInterface {
  const router = useRouter();
  const context = useContext();
  const { selectedCurrency } = useMagentoConfiguration();

  // Product Impressions
  // https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#product-impressions
  const gtmProductImpressions = (products: Array<any>, category?: string) => {
    pushToDataLayer({
      event: 'productImpression',
      ecommerce: {
        currencyCode: selectedCurrency.value,
        impressions: [convertProductListToGTM(products, category)],
      },
    });
  };

  // Product Details
  // https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#details
  const gtmProductDetails = (product: Product) => {
    pushToDataLayer({
      event: 'productDetail',
      ecommerce: {
        detail: {
          products: [convertProductToGTM(product)],
        },
      },
    });

    // extra required for GTM Facebook Pixel integration
    if (process.client && typeof (window as any).fbq !== 'undefined') {
      (window as any).fbq('track', 'ViewContent', {
        content_name: product.name,
        content_ids: [product.uid],
        content_type: 'product',
        value: product.price_range.minimum_price.final_price.value,
        currency: selectedCurrency.value,
      });
    }
  };

  // Product Clicks
  // https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#product-clicks
  const gtmProductClick = (product: Product) => {
    const productPath = `/${product?.url_rewrites?.[0]?.url ?? product.url_key}`;
    const link = context.localeRoute(productPath);

    if (!window['google_tag_manager']) {
      router.push(link);
      return;
    }

    pushToDataLayer({
      event: 'productClick',
      ecommerce: {
        click: {
          products: [convertProductToGTM(product)],
        },
      },
      eventCallback: function () {
        router.push(link);
      },
    });
  };

  // Add to Cart
  // https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#cart
  const gtmAddToCart = (product: Product, quantity?: Number) => {
    pushToDataLayer({
      event: 'addToCart',
      ecommerce: {
        currencyCode: selectedCurrency.value,
        add: {
          // 'add' actionFieldObject measures.
          products: [convertProductToGTM(product, quantity)],
        },
      },
    });
  };

  // Remove from Cart
  // https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#cart
  const gtmRemoveFromCart = (item: { product: Product; quantity: Number }) => {
    pushToDataLayer({
      event: 'removeFromCart',
      ecommerce: {
        currencyCode: selectedCurrency.value,
        remove: {
          // 'add' actionFieldObject measures.
          products: [convertProductToGTM(item.product, item.quantity)],
        },
      },
    });
  };

  // Checkout
  // https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#checkout
  const gtmCheckout = (products: Array<any>, redirect: string) => {
    if (!window['google_tag_manager']) {
      router.push(redirect);
      return;
    }

    pushToDataLayer({
      event: 'checkout',
      ecommerce: {
        checkout: {
          products: convertCheckoutProductsToGTM(products),
        },
      },
      eventCallback: async function () {
        await router.push(redirect);
      },
    });
  };

  // Checkout Option
  // https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#checkout
  const gtmCheckoutOption = (step: number, checkoutOption: string) => {
    pushToDataLayer({
      event: 'checkoutOption',
      ecommerce: {
        checkout_option: {
          actionField: { step: step, option: checkoutOption },
        },
      },
    });
  };

  // Add Shipping Info
  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=14610459269524995325-EU&client_type=gtag#add_shipping_info
  const gtmAddShippingInfo = (products: Array<any>, shipping: string, amount: number) => {
    pushToDataLayer({
      event: 'addShippingInfo',
      ecommerce: {
        currencyCode: selectedCurrency.value,
        shipping_tier: shipping,
        value: amount,
        items: convertCheckoutProductsToGTM(products),
      },
    });
  };

  // Purchases
  // https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#purchases
  const gtmPurchases = (params: UseGtmPurchasesParams) => {
    pushToDataLayer({
      event: 'purchase',
      ecommerce: {
        currencyCode: selectedCurrency.value,
        payment_type: params.paymentMethod,
        shipping_tier: params.shippingMethod,
        purchase: {
          actionField: {
            id: params.orderId, // Transaction ID. Required for purchases and refunds.
            affiliation: 'Online Store',
            revenue: params.total, // Total transaction value (incl. tax and shipping)
            tax: params.tax,
            shipping: params.shipping,
            coupon: params.coupon,
          },
          products: convertCheckoutProductsToGTM(params.products),
        },
      },
    });
  };

  return {
    gtmProductImpressions,
    gtmProductDetails,
    gtmProductClick,
    gtmAddToCart,
    gtmRemoveFromCart,
    gtmCheckout,
    gtmCheckoutOption,
    gtmAddShippingInfo,
    gtmPurchases,
  };
}
