import { Middleware } from '@nuxt/types';
import { usePageStore } from '~/stores/page';
import { Logger } from '~/helpers/logger';
import { RoutableInterface } from '~/modules/GraphQL/types';
import { useStoryblokStore } from '~/modules/storyblok/stores/storyblok';

const urlResolverMiddleware: Middleware = async (context) => {
  const pageStore = usePageStore();
  const storyblok = useStoryblokStore();
  const { path, params } = context.route;

  const clearUrl = path.replace(/[a-z]+\/[cp|]\//gi, '').replace(`/${context.i18n.locale}`, '');

  Logger.debug('middleware/url-resolver', clearUrl);

  const { data, errors } = await context.app.$vsf.$magento.api.route(clearUrl);

  Logger.debug('middleware/url-resolver/result', { data, errors });

  const results: RoutableInterface | null = data?.route ?? null;

  let storyblokResults;

  // No results or errors from Magento
  if (!results || errors?.length) {
    // Check if there is a Storyblok page matching the route slug
    await storyblok.loadCmsPage(params.slug);
    if (storyblok.pages[params.slug]) {
      storyblokResults = {
        uid: storyblok.pages[params.slug].uuid,
        type: 'STORYBLOK',
        slug: storyblok.pages[params.slug].full_slug,
      };
    } else {
      context.error({ statusCode: 404 });
    }
  }

  // Add page result to store
  pageStore.$patch((state) => {
    state.routeData = results ?? storyblokResults;
  });
};

export default urlResolverMiddleware;
