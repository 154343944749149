//
//
//
//
//

import { ref, defineComponent, useFetch } from '@nuxtjs/composition-api';
import { useStoryblokStore } from '~/modules/storyblok/stores/storyblok';

export default defineComponent({
  name: 'Theme',
  setup() {
    const storyblok = useStoryblokStore();
    const theme = ref(null);

    useFetch(async () => {
      await storyblok.load();
      theme.value = storyblok.global.content.theme;
    });
    return { theme };
  },
});
