













import { defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import type { NuxtError } from '@nuxt/types';
import { useErrorStore } from '~/stores/error';

export default defineComponent({
  components: {
    PageNotFound: () => import(/* webpackPrefetch: true */ '~/components/error/PageNotFound.vue'),
    Maintenance: () => import(/* webpackPrefetch: true */ '~/components/error/Maintenance.vue'),
  },
  name: 'Error',
  props: {
    error: {
      type: Object as PropType<NuxtError>,
      required: true,
    },
  },
  layout() {
    return 'default';
  },
  setup(props) {
    const error = useErrorStore();
    error.setError(props.error);
  },
});
