// image sizes worked out on ratio 1:1.25
export default {
  productCard: {
    width: 597,
    height: 747,
  },
  productCardHorizontal: {
    width: 177,
    height: 220,
  },
  productCardCompact: {
    width: 282,
    height: 352,
  },
  productCardCarousel: {
    width: 350,
    height: 438,
  },
  checkout: {
    width: 100,
    height: 125,
  },
  productGallery: {
    thumbWidth: 572,
    thumbHeight: 715,
    imageWidth: 960,
    imageHeight: 1200,
  },
  cart: {
    width: 170,
    height: 212,
  },
  cartItem: {
    width: 100,
    height: 125,
  },
  miniCart: {
    width: 82,
    height: 102,
  },
  recentlyViewed: {
    width: 160,
    height: 200,
  },
  searchOverlay: {
    width: 75,
    height: 94,
  },
  kitbuilderGallery: {
    thumbWidth: 572,
    thumbHeight: 715,
    imageWidth: 960,
    imageHeight: 1200,
  },
};
