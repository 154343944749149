const middleware = {}

middleware['checkout'] = require('../middleware/checkout.ts')
middleware['checkout'] = middleware['checkout'].default || middleware['checkout']

middleware['is-authenticated'] = require('../middleware/is-authenticated.ts')
middleware['is-authenticated'] = middleware['is-authenticated'].default || middleware['is-authenticated']

middleware['maintenance'] = require('../middleware/maintenance.ts')
middleware['maintenance'] = middleware['maintenance'].default || middleware['maintenance']

middleware['order-success'] = require('../middleware/order-success.ts')
middleware['order-success'] = middleware['order-success'].default || middleware['order-success']

middleware['sso-cookie'] = require('../middleware/sso-cookie.ts')
middleware['sso-cookie'] = middleware['sso-cookie'].default || middleware['sso-cookie']

middleware['url-resolver'] = require('../middleware/url-resolver.ts')
middleware['url-resolver'] = middleware['url-resolver'].default || middleware['url-resolver']

export default middleware
