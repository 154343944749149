import { computed, reactive } from '@nuxtjs/composition-api';
import { StateInterface, UseUiStateInterface } from '~/composables/useUiState/useUiState';

const state = reactive<StateInterface>({
  isCartSidebarOpen: false,
  isWishlistSidebarOpen: false,
  isLoginModalOpen: false,
  isNewsletterModalOpen: false,
  isCategoryGridView: true,
  isWishlistGridView: true,
  isFilterSidebarOpen: false,
  isMobileMenuOpen: false,
  /**
   * Start Custom additions
   */
  isMegaMenuOpen: false,
  isSortByOpen: false,
  isLocalisationOpen: false,
  isHeaderSticky: true,
  isFilterSidebarOpenDesktop: true,
  isSearchOpen: false,
  isCurrencyOpen: false,
  isEditingShippingAddress: false,
  isGlobalLinksOpen: false,
  /**
   * End Custom additions
   */
});

/**
 * Global store for managing UI state.
 *
 * See the {@link UseUiStateInterface} for a list of methods and values available in this composable.
 */
export function useUiState(): UseUiStateInterface {
  const toggleMobileMenu = () => {
    state.isMobileMenuOpen = !state.isMobileMenuOpen;
  };

  const toggleCartSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    if (state.isLocalisationOpen) toggleLocalisation();
    if (state.isCurrencyOpen) toggleCurrency();
    if (state.isSearchOpen) toggleSearch(false);
    state.isCartSidebarOpen = !state.isCartSidebarOpen;
  };

  const toggleWishlistSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isWishlistSidebarOpen = !state.isWishlistSidebarOpen;
  };

  const toggleLoginModal = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isLoginModalOpen = !state.isLoginModalOpen;
  };

  const toggleNewsletterModal = () => {
    state.isNewsletterModalOpen = !state.isNewsletterModalOpen;
  };

  const changeToCategoryGridView = () => {
    state.isCategoryGridView = true;
  };
  const changeToCategoryListView = () => {
    state.isCategoryGridView = false;
  };

  const changeToWishlistGridView = () => {
    state.isWishlistGridView = true;
  };

  const changeToWishlistListView = () => {
    state.isWishlistGridView = false;
  };
  const toggleFilterSidebar = () => {
    state.isFilterSidebarOpen = !state.isFilterSidebarOpen;
  };

  /**
   * Start Custom additions
   */
  const toggleMegaMenu = (payload: boolean = false) => {
    if (state.isCartSidebarOpen) toggleCartSidebar();
    if (state.isLocalisationOpen) toggleLocalisation();
    state.isMegaMenuOpen = payload;
  };
  const toggleSortBy = (payload: boolean = false) => {
    state.isSortByOpen = !state.isSortByOpen;
  };
  const closeSortBy = () => {
    state.isSortByOpen = false;
  };
  const toggleLocalisation = () => {
    if (state.isCartSidebarOpen) toggleCartSidebar();
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isLocalisationOpen = !state.isLocalisationOpen;
  };

  const toggleFilterSidebarDesktop = () => {
    state.isFilterSidebarOpenDesktop = !state.isFilterSidebarOpenDesktop;
  };
  const toggleSearch = (payload: boolean = false) => {
    if (state.isCartSidebarOpen) toggleCartSidebar();
    // if (state.isLocalisationOpen) toggleLocalisation();
    if (state.isCurrencyOpen) toggleCurrency();
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isSearchOpen = payload;
  };
  const toggleCurrency = () => {
    if (state.isCartSidebarOpen) toggleCartSidebar();
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isCurrencyOpen = !state.isCurrencyOpen;
  };

  const toggleEditingShippingAddress = () => {
    state.isEditingShippingAddress = !state.isEditingShippingAddress;
  };

  const stopEditingShippingAddress = () => {
    state.isEditingShippingAddress = false;
  };

  const startEditingShippingAddress = () => {
    state.isEditingShippingAddress = true;
  };
  const toggleGlobalLinks = () => {
    state.isGlobalLinksOpen = !state.isGlobalLinksOpen;
  };
  /**
   * End Custom additions
   */

  return {
    isMobileMenuOpen: computed(() => state.isMobileMenuOpen),
    isCartSidebarOpen: computed(() => state.isCartSidebarOpen),
    isWishlistSidebarOpen: computed(() => state.isWishlistSidebarOpen),
    isLoginModalOpen: computed(() => state.isLoginModalOpen),
    isNewsletterModalOpen: computed(() => state.isNewsletterModalOpen),
    isCategoryGridView: computed(() => state.isCategoryGridView),
    isWishlistGridView: computed(() => state.isWishlistGridView),
    isFilterSidebarOpen: computed(() => state.isFilterSidebarOpen),
    toggleMobileMenu,
    toggleCartSidebar,
    toggleWishlistSidebar,
    toggleLoginModal,
    toggleNewsletterModal,
    changeToCategoryGridView,
    changeToCategoryListView,
    changeToWishlistGridView,
    changeToWishlistListView,
    toggleFilterSidebar,

    /**
     * Start Custom additions
     */
    isMegaMenuOpen: computed(() => state.isMegaMenuOpen),
    isSortByOpen: computed(() => state.isSortByOpen),
    isLocalisationOpen: computed(() => state.isLocalisationOpen),
    isHeaderSticky: computed(() => state.isHeaderSticky),
    isFilterSidebarOpenDesktop: computed(() => state.isFilterSidebarOpenDesktop),
    isSearchOpen: computed(() => state.isSearchOpen),
    isCurrencyOpen: computed(() => state.isCurrencyOpen),
    isEditingShippingAddress: computed(() => state.isEditingShippingAddress),
    isGlobalLinksOpen: computed(() => state.isGlobalLinksOpen),

    toggleMegaMenu,
    toggleSortBy,
    toggleLocalisation,
    closeSortBy,
    toggleFilterSidebarDesktop,
    toggleSearch,
    toggleCurrency,
    toggleEditingShippingAddress,
    stopEditingShippingAddress,
    startEditingShippingAddress,
    toggleGlobalLinks,
    /**
     * End Custom additions
     */
  };
}

export default useUiState;
export * from './useUiState';
