import { defineStore } from 'pinia';
import _merge from 'lodash-es/merge';
import { useStoryblokApi } from '@storyblok/nuxt-2';
import { useConfigStore } from '~/stores/config';

interface UseStoryblokState {
  spaceId: string;
  cacheVersion: string;
  rawGlobal: {
    story: {
      id: string;
      content: {
        logo: any;
        logoWidth: any;
        logoHeight: any;
        header: any;
        bottom: any;
        exitCapture: any;
        usps: any;
        theme: string;
      };
    };
  } | null;
  rawHome: {
    story: {
      id: string;
      content: {
        header: any;
        bottom: any;
      };
    };
  } | null;
  rawCart: {
    story: {
      id: string;
      content: {
        message: object;
        showMessage: boolean;
        messageIcon: string;
      };
    };
  } | null;
  rawCategories: Object;
  rawCmsPages: Object;
}

export const useStoryblokStore = defineStore('storyblok', {
  state: (): UseStoryblokState => ({
    cacheVersion: null,
    spaceId: null,
    rawGlobal: null,
    rawHome: null,
    rawCart: null,
    rawCategories: {},
    rawCmsPages: {},
  }),
  actions: {
    async load() {
      // check if we have latest cache version, if not get it
      if (!this.cacheVersion) {
        await this.getCacheVersion();
      }

      // check if we have story already loaded in store for this slug
      if (this.global) {
        return;
      }

      // If content is not in store get from API
      const storyblokApi = useStoryblokApi();

      const { data } = await storyblokApi.get('cdn/stories/global', {
        version: this.version,
        language: this.locale,
        cv: this.cacheVersion,
      });
      this.rawGlobal = data ?? null;
    },
    async loadHome() {
      // check if we have latest cache version, if not get it
      if (!this.cacheVersion) {
        await this.getCacheVersion();
      }

      // check if we have story already loaded in store for this slug
      if (this.home) {
        return;
      }
      // If content is not in store get from API
      const storyblokApi = useStoryblokApi();

      const { data } = await storyblokApi.get('cdn/stories/home', {
        version: this.version,
        language: this.locale,
        cv: this.cacheVersion,
      });
      this.rawHome = data ?? null;
    },
    async loadCart() {
      // check if we have latest cache version, if not get it
      if (!this.cacheVersion) {
        await this.getCacheVersion();
      }

      // check if we have story already loaded in store for this slug
      if (this.cart) {
        return;
      }
      // If content is not in store get from API
      const storyblokApi = useStoryblokApi();

      const { data } = await storyblokApi.get('cdn/stories/cart', {
        version: this.version,
        language: this.locale,
        cv: this.cacheVersion,
      });
      this.rawCart = data ?? null;
    },
    async loadCategory(slug: string) {
      if (!slug) return;
      // check if we have latest cache version, if not get it
      if (!this.cacheVersion) {
        await this.getCacheVersion();
      }

      const storyblokApi = useStoryblokApi();

      if (this.categories[slug]) {
        return;
      }

      const { data } = await storyblokApi.get('cdn/stories/', {
        version: this.version,
        starts_with: 'c/',
        by_slugs: '*/' + slug,
        language: this.locale,
        cv: this.cacheVersion,
      });

      if (!data.stories.length) return;

      let category = {};
      category[slug] = data;
      this.rawCategories = _merge(this.rawCategories, category);
    },
    async loadCmsPage(slug: string) {
      // check if we have story already loaded in store for this slug
      if (this.pages[slug]) {
        // return story from store
        return this.pages[slug];
      } else {
        // check if we have latest cache version, if not get it
        if (!this.cacheVersion) {
          await this.getCacheVersion();
        }

        // try and load story from API using slug
        const storyblokApi = useStoryblokApi();
        await storyblokApi
          .get(`cdn/stories/${slug}`, {
            version: this.version,
            language: this.locale,
            cv: this.cacheVersion,
          })
          .then((response) => {
            // add page to store
            let page = {};
            page[slug] = response.data.story;
            this.rawCmsPages = _merge(this.rawCmsPages, page);

            return page;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    async getCacheVersion() {
      const storyblokApi = useStoryblokApi();

      const { data } = await storyblokApi.get('cdn/spaces/me', {});

      this.cacheVersion = data.space.version;
      this.spaceId = data.space.id;
    },
  },
  getters: {
    global(state) {
      return state.rawGlobal?.story || null;
    },
    bottom(state) {
      return state.rawGlobal?.story?.content?.bottom || null;
    },
    header(state) {
      return state.rawGlobal?.story?.content?.header || null;
    },
    home(state) {
      return state.rawHome?.story || null;
    },
    cart(state) {
      return state.rawCart?.story || null;
    },
    categories(state) {
      return state.rawCategories || null;
    },
    pages(state) {
      return state.rawCmsPages || null;
    },
    locale() {
      const config = useConfigStore();
      return config.storeConfig.store_code;
    },
    version() {
      // @ts-ignore
      return this.$nuxt.$config.storyblokVersion;
    },
    theme(state) {
      return state.rawGlobal?.story?.content?.theme || 'default';
    },
  },
});
