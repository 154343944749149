import { Middleware } from '@nuxt/types';
import { useCheckoutStore } from '~/modules/checkout/stores/checkout';

const middleware: Middleware = async (context) => {
  const checkoutStore = useCheckoutStore();
  if (!checkoutStore.orderId) {
    const home = context.localeRoute({ path: '/' });
    context.redirect(home);
  }
};
export default middleware;
