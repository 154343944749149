import { defineStore } from 'pinia';

interface ErrorState {
  error: {
    message: String;
    statusCode: Number;
  };
}

export const useErrorStore = defineStore('error', {
  state: (): ErrorState => {
    return {
      error: {
        message: '',
        statusCode: 200,
      },
    };
  },
  getters: {
    getError: (state) => {
      return state.error;
    },
  },
  actions: {
    setError(err) {
      this.error = err;
    },
  },
});
