import { Middleware } from '@nuxt/types';
import useSsoCookie from '~/modules/sso/composables/useSsoCookie';

const middleware: Middleware = async (context) => {
  if (process.client) {
    const { ssoCookieNonRedirect } = useSsoCookie();
    ssoCookieNonRedirect(context);
  }
};

export default middleware;
