import Vue from 'vue'

import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'

/* Plugins */

import nuxt_plugin_plugin_3355203c from 'nuxt_plugin_plugin_3355203c' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_plugin_0f6af74a from 'nuxt_plugin_plugin_0f6af74a' // Source: ./composition-api/plugin.mjs (mode: 'all')
import nuxt_plugin_recaptcha_6cf89f32 from 'nuxt_plugin_recaptcha_6cf89f32' // Source: ./recaptcha.js (mode: 'all')
import nuxt_plugin_pinoclient_0cfe8c62 from 'nuxt_plugin_pinoclient_0cfe8c62' // Source: ./pino.client.js (mode: 'client')
import nuxt_plugin_pinoserver_5f3a2b57 from 'nuxt_plugin_pinoserver_5f3a2b57' // Source: ./pino.server.js (mode: 'server')
import nuxt_plugin_pluginutils_966f817a from 'nuxt_plugin_pluginutils_966f817a' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_59b607ce from 'nuxt_plugin_pluginrouting_59b607ce' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_1d2ea661 from 'nuxt_plugin_pluginmain_1d2ea661' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_nuxtplugin1119a731_1c92c4d4 from 'nuxt_plugin_nuxtplugin1119a731_1c92c4d4' // Source: ./nuxt.plugin.1119a731.js (mode: 'server')
import nuxt_plugin_pluginserver_3b0d0e66 from 'nuxt_plugin_pluginserver_3b0d0e66' // Source: ./nuxt-speedkit/plugin.server.js (mode: 'server')
import nuxt_plugin_pluginclient_5558c644 from 'nuxt_plugin_pluginclient_5558c644' // Source: ./nuxt-speedkit/plugin.client.js (mode: 'client')
import nuxt_plugin_gtm_6a527a64 from 'nuxt_plugin_gtm_6a527a64' // Source: ./gtm.js (mode: 'all')
import nuxt_plugin_vuescrollto_033d0111 from 'nuxt_plugin_vuescrollto_033d0111' // Source: ./vue-scrollto.js (mode: 'client')
import nuxt_plugin_cookieuniversalnuxt_7914e4c0 from 'nuxt_plugin_cookieuniversalnuxt_7914e4c0' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_nuxtsvgsprite_4e99a316 from 'nuxt_plugin_nuxtsvgsprite_4e99a316' // Source: ./nuxt-svg-sprite.js (mode: 'all')
import nuxt_plugin_moduleplugin03ed549f_d87daf34 from 'nuxt_plugin_moduleplugin03ed549f_d87daf34' // Source: ./module.plugin.03ed549f.mjs (mode: 'all')
import nuxt_plugin_distplugin74388324_4bb93451 from 'nuxt_plugin_distplugin74388324_4bb93451' // Source: ./dist.plugin.74388324.mjs (mode: 'all')
import nuxt_plugin_image_87644a1a from 'nuxt_plugin_image_87644a1a' // Source: ./image.js (mode: 'all')
import nuxt_plugin_coreplugin9ecfa96a_68ea6b38 from 'nuxt_plugin_coreplugin9ecfa96a_68ea6b38' // Source: ./core.plugin.9ecfa96a.ts (mode: 'all')
import nuxt_plugin_pluginse2etesting6b83592a_39a55345 from 'nuxt_plugin_pluginse2etesting6b83592a_39a55345' // Source: ./plugins.e2e-testing.6b83592a.js (mode: 'all')
import nuxt_plugin_pluginsssr6e3f5858_2f52266e from 'nuxt_plugin_pluginsssr6e3f5858_2f52266e' // Source: ./plugins.ssr.6e3f5858.js (mode: 'all')
import nuxt_plugin_deviceplugin_140604fa from 'nuxt_plugin_deviceplugin_140604fa' // Source: ./device.plugin.js (mode: 'all')
import nuxt_plugin_workbox_1cd7b514 from 'nuxt_plugin_workbox_1cd7b514' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_0f04a827 from 'nuxt_plugin_metaplugin_0f04a827' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_iconplugin_13ac4d9b from 'nuxt_plugin_iconplugin_13ac4d9b' // Source: ./pwa/icon.plugin.js (mode: 'all')
import nuxt_plugin_tokenexpired_ca1e20ca from 'nuxt_plugin_tokenexpired_ca1e20ca' // Source: ../plugins/token-expired (mode: 'all')
import nuxt_plugin_i18n_6a80ea94 from 'nuxt_plugin_i18n_6a80ea94' // Source: ../plugins/i18n (mode: 'all')
import nuxt_plugin_fcPlugin_5a846bc6 from 'nuxt_plugin_fcPlugin_5a846bc6' // Source: ../plugins/fcPlugin (mode: 'all')
import nuxt_plugin_dompurify_9e093c4a from 'nuxt_plugin_dompurify_9e093c4a' // Source: ../plugins/dompurify (mode: 'all')
import nuxt_plugin_storeConfigPlugin_234467e0 from 'nuxt_plugin_storeConfigPlugin_234467e0' // Source: ../plugins/storeConfigPlugin (mode: 'all')
import nuxt_plugin_piniapluginpersist_06683d2e from 'nuxt_plugin_piniapluginpersist_06683d2e' // Source: ../plugins/pinia-plugin-persist.client (mode: 'client')
import nuxt_plugin_geoip_9a2497a2 from 'nuxt_plugin_geoip_9a2497a2' // Source: ../plugins/geoip.server (mode: 'server')
import nuxt_plugin_ssocookie_6ffd3ea1 from 'nuxt_plugin_ssocookie_6ffd3ea1' // Source: ../plugins/sso-cookie.client (mode: 'client')
import nuxt_plugin_vueselect_61fca840 from 'nuxt_plugin_vueselect_61fca840' // Source: ../plugins/vue-select.client (mode: 'client')
import nuxt_plugin_logger_52e3bef4 from 'nuxt_plugin_logger_52e3bef4' // Source: ../plugins/logger (mode: 'all')
import nuxt_plugin_meta_015c1cdc from 'nuxt_plugin_meta_015c1cdc' // Source: ./composition-api/meta.mjs (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp(ssrContext, config = {}) {
  const store = null
  const router = await createRouter(ssrContext, config, { store })

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Leicester City - Official Store","meta":[{"charset":"utf8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"robots","content":"index,follow"},{"name":"generator","content":"SLIDE 7"},{"name":"generator","content":"SLIDE 7"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"},{"rel":"preconnect","href":"https:\u002F\u002Fres-4.cloudinary.com","crossorigin":true},{"rel":"dns-prefetch","href":"https:\u002F\u002Fres-4.cloudinary.com"}],"__dangerouslyDisableSanitizers":["script"],"script":[{"hid":"freshrelevance","src":"\u002F\u002Fd81mfvml8p5ml.cloudfront.net\u002Fjfj9w98w.js","defer":true},{"hid":"gtm-script","innerHTML":"if(!window._gtm_init){window._gtm_init=1;(function(w,n,d,m,e,p){w[d]=(w[d]==1||n[d]=='yes'||n[d]==1||n[m]==1||(w[e]&&w[e][p]&&w[e][p]()))?1:0})(window,navigator,'doNotTrack','msDoNotTrack','external','msTrackingProtectionEnabled');(function(w,d,s,l,x,y){w[x]={};w._gtm_inject=function(i){if(w.doNotTrack||w[x][i])return;w[x][i]=1;w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.async=true;j.src='https:\u002F\u002Fwww.googletagmanager.com\u002Fgtm.js?id='+i;f.parentNode.insertBefore(j,f);};w[y]('GTM-MLGK6G3')})(window,document,'script','dataLayer','_gtm_ids','_gtm_inject')}"}],"style":[],"noscript":[{"hid":"gtm-noscript","pbody":true,"innerHTML":"\u003Ciframe src=\"https:\u002F\u002Fwww.googletagmanager.com\u002Fns.html?id=GTM-MLGK6G3&\" height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\" title=\"gtm\"\u003E\u003C\u002Fiframe\u003E"}],"__dangerouslyDisableSanitizersByTagID":{"gtm-script":["innerHTML"],"gtm-noscript":["innerHTML"]}},

    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_3355203c === 'function') {
    await nuxt_plugin_plugin_3355203c(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_0f6af74a === 'function') {
    await nuxt_plugin_plugin_0f6af74a(app.context, inject)
  }

  if (typeof nuxt_plugin_recaptcha_6cf89f32 === 'function') {
    await nuxt_plugin_recaptcha_6cf89f32(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pinoclient_0cfe8c62 === 'function') {
    await nuxt_plugin_pinoclient_0cfe8c62(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_pinoserver_5f3a2b57 === 'function') {
    await nuxt_plugin_pinoserver_5f3a2b57(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_966f817a === 'function') {
    await nuxt_plugin_pluginutils_966f817a(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_59b607ce === 'function') {
    await nuxt_plugin_pluginrouting_59b607ce(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_1d2ea661 === 'function') {
    await nuxt_plugin_pluginmain_1d2ea661(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_nuxtplugin1119a731_1c92c4d4 === 'function') {
    await nuxt_plugin_nuxtplugin1119a731_1c92c4d4(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_pluginserver_3b0d0e66 === 'function') {
    await nuxt_plugin_pluginserver_3b0d0e66(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pluginclient_5558c644 === 'function') {
    await nuxt_plugin_pluginclient_5558c644(app.context, inject)
  }

  if (typeof nuxt_plugin_gtm_6a527a64 === 'function') {
    await nuxt_plugin_gtm_6a527a64(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuescrollto_033d0111 === 'function') {
    await nuxt_plugin_vuescrollto_033d0111(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_7914e4c0 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_7914e4c0(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtsvgsprite_4e99a316 === 'function') {
    await nuxt_plugin_nuxtsvgsprite_4e99a316(app.context, inject)
  }

  if (typeof nuxt_plugin_moduleplugin03ed549f_d87daf34 === 'function') {
    await nuxt_plugin_moduleplugin03ed549f_d87daf34(app.context, inject)
  }

  if (typeof nuxt_plugin_distplugin74388324_4bb93451 === 'function') {
    await nuxt_plugin_distplugin74388324_4bb93451(app.context, inject)
  }

  if (typeof nuxt_plugin_image_87644a1a === 'function') {
    await nuxt_plugin_image_87644a1a(app.context, inject)
  }

  if (typeof nuxt_plugin_coreplugin9ecfa96a_68ea6b38 === 'function') {
    await nuxt_plugin_coreplugin9ecfa96a_68ea6b38(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginse2etesting6b83592a_39a55345 === 'function') {
    await nuxt_plugin_pluginse2etesting6b83592a_39a55345(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsssr6e3f5858_2f52266e === 'function') {
    await nuxt_plugin_pluginsssr6e3f5858_2f52266e(app.context, inject)
  }

  if (typeof nuxt_plugin_deviceplugin_140604fa === 'function') {
    await nuxt_plugin_deviceplugin_140604fa(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_1cd7b514 === 'function') {
    await nuxt_plugin_workbox_1cd7b514(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_0f04a827 === 'function') {
    await nuxt_plugin_metaplugin_0f04a827(app.context, inject)
  }

  if (typeof nuxt_plugin_iconplugin_13ac4d9b === 'function') {
    await nuxt_plugin_iconplugin_13ac4d9b(app.context, inject)
  }

  if (typeof nuxt_plugin_tokenexpired_ca1e20ca === 'function') {
    await nuxt_plugin_tokenexpired_ca1e20ca(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_6a80ea94 === 'function') {
    await nuxt_plugin_i18n_6a80ea94(app.context, inject)
  }

  if (typeof nuxt_plugin_fcPlugin_5a846bc6 === 'function') {
    await nuxt_plugin_fcPlugin_5a846bc6(app.context, inject)
  }

  if (typeof nuxt_plugin_dompurify_9e093c4a === 'function') {
    await nuxt_plugin_dompurify_9e093c4a(app.context, inject)
  }

  if (typeof nuxt_plugin_storeConfigPlugin_234467e0 === 'function') {
    await nuxt_plugin_storeConfigPlugin_234467e0(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_piniapluginpersist_06683d2e === 'function') {
    await nuxt_plugin_piniapluginpersist_06683d2e(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_geoip_9a2497a2 === 'function') {
    await nuxt_plugin_geoip_9a2497a2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_ssocookie_6ffd3ea1 === 'function') {
    await nuxt_plugin_ssocookie_6ffd3ea1(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueselect_61fca840 === 'function') {
    await nuxt_plugin_vueselect_61fca840(app.context, inject)
  }

  if (typeof nuxt_plugin_logger_52e3bef4 === 'function') {
    await nuxt_plugin_logger_52e3bef4(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_015c1cdc === 'function') {
    await nuxt_plugin_meta_015c1cdc(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    app,
    router
  }
}

export { createApp, NuxtError }
