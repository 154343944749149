import { CustomQuery, CustomHeaders, UseContextReturn } from '~/types/core';
import { CustomerAddressInput } from '~/modules/GraphQL/types';

export const updateCustomerAddressCommand = {
  execute: async (
    context: UseContextReturn,
    params: {
      addressId: number;
      input: CustomerAddressInput;
    },
    customQuery: CustomQuery,
    customHeaders: CustomHeaders
  ) => {
    const { data } = await context.app.$vsf.$magento.api.updateCustomerAddress(
      {
        // @ts-ignore TODO: fix types in api-client
        id: params.addressId,
        input: {
          ...params.input,
        },
      },
      customQuery,
      customHeaders
    );

    return data?.updateCustomerAddress ?? {};
  },
};
