







import { useRoute, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'Empty',

  setup() {
    const route = useRoute();

    return {
      route,
    };
  },
  head() {
    return this.$nuxtI18nHead({ addSeoAttributes: true });
  },
});
