import useAlgolia from '~/modules/algolia/composables/useAlgolia';
import type { useAlgoliaEventsInterface } from './useAlgoliaEvents';
import aa from 'search-insights';

export function useAlgoliaEvents(): useAlgoliaEventsInterface {
  const { productIndex, userToken } = useAlgolia();

  // Tracking Events
  const algoliaProductClicked = (item: string) => {
    aa('clickedObjectIDs', {
      userToken: userToken.value,
      index: productIndex.value,
      eventName: 'Product Clicked',
      objectIDs: [item],
    });
  };

  const algoliaProductViewed = (item: string) => {
    aa('viewedObjectIDs', {
      userToken: userToken.value,
      index: productIndex.value,
      eventName: 'Product Viewed',
      objectIDs: [item],
    });
  };

  const algoliaFiltersViewed = (items) => {
    if (items.length) {
      aa('viewedFilters', {
        userToken: userToken.value,
        index: productIndex.value,
        eventName: 'Filters Viewed',
        filters: items,
      });
    }
  };

  const algoliaClickedFilters = (items) => {
    if (items.length) {
      aa('clickedFilters', {
        userToken: userToken.value,
        index: productIndex.value,
        eventName: 'Filters Clicked',
        filters: items,
      });
    }
  };

  const algoliaConvertedFilters = (items) => {
    if (items.length) {
      aa('convertedFilters', {
        userToken: userToken.value,
        index: productIndex.value,
        eventName: 'Filters Converted',
        filters: items,
      });
    }
  };

  const algoliaConvertAfterSearch = (item: string) => {
    aa('convertedObjectIDsAfterSearch', {
      userToken: userToken.value,
      index: productIndex.value,
      eventName: 'Product Added to Cart after Search',
      queryID: localStorage.getItem('algoliaQueryID'),
      objectIDs: [item],
    });
  };

  const algoliaConvert = (item: string) => {
    aa('convertedObjectIDs', {
      userToken: userToken.value,
      index: productIndex.value,
      eventName: 'Product Added to Cart',
      objectIDs: [item],
    });
  };

  return {
    algoliaProductClicked,
    algoliaClickedFilters,
    algoliaConvertAfterSearch,
    algoliaConvert,
    algoliaFiltersViewed,
    algoliaProductViewed,
    algoliaConvertedFilters,
  };
}

export default useAlgoliaEvents;
