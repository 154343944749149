import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _f03313ca = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _207d8b4a = () => interopDefault(import('../modules/adyen/pages/Adyen.vue' /* webpackChunkName: "" */))
const _40e077a4 = () => interopDefault(import('../modules/adyen/pages/AdyenDirect.vue' /* webpackChunkName: "" */))
const _474ebe2b = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _9d396862 = () => interopDefault(import('../modules/catalog/pages/categoryLanding.vue' /* webpackChunkName: "" */))
const _ed42c85e = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _0fb11628 = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _221e1408 = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _4f027cba = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _538cbcbd = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _14d1c9f0 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _1da6215e = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _ce69b974 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _39efeb4c = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _334b8cde = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _355b0792 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _2b6254c4 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _42bb76ac = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _45cf1c19 = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _05ab6170 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _1c78e2d6 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _2d4e1d87 = () => interopDefault(import('../pages/GiftCards.vue' /* webpackChunkName: "" */))
const _dcf055ce = () => interopDefault(import('../pages/GlobalPreview.vue' /* webpackChunkName: "" */))
const _da659846 = () => interopDefault(import('../pages/Login.vue' /* webpackChunkName: "" */))
const _5629c4f2 = () => interopDefault(import('../pages/Maintenance.vue' /* webpackChunkName: "" */))
const _4fc8e86a = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _3df1afca = () => interopDefault(import('../modules/algolia/pages/search.vue' /* webpackChunkName: "" */))
const _5251f427 = () => interopDefault(import('../pages/Styleguide.vue' /* webpackChunkName: "" */))
const _9ca6e2b0 = () => interopDefault(import('../modules/checkout/pages/SecureCheckout.vue' /* webpackChunkName: "" */))
const _c81019e8 = () => interopDefault(import('../modules/sso/pages/Postauth.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _f03313ca,
    name: "home___en"
  }, {
    path: "/th",
    component: _f03313ca,
    name: "home___th"
  }, {
    path: "/en/adyen",
    component: _207d8b4a,
    name: "adyen___en"
  }, {
    path: "/en/adyen-direct",
    component: _40e077a4,
    name: "adyen-direct___en"
  }, {
    path: "/en/cart",
    component: _474ebe2b,
    name: "cart___en"
  }, {
    path: "/en/category-landing",
    component: _9d396862,
    name: "category-landing___en"
  }, {
    path: "/en/checkout",
    component: _ed42c85e,
    name: "checkout___en",
    children: [{
      path: "payment",
      component: _0fb11628,
      name: "payment___en"
    }, {
      path: "shipping",
      component: _221e1408,
      name: "shipping___en"
    }, {
      path: "thank-you",
      component: _4f027cba,
      name: "thank-you___en"
    }]
  }, {
    path: "/en/Cms",
    component: _538cbcbd,
    name: "Cms___en"
  }, {
    path: "/en/customer",
    component: _14d1c9f0,
    meta: {"titleLabel":"My Account"},
    name: "customer___en",
    children: [{
      path: "addresses-details",
      component: _1da6215e,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___en"
    }, {
      path: "my-newsletter",
      component: _ce69b974,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___en"
    }, {
      path: "my-profile",
      component: _39efeb4c,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___en"
    }, {
      path: "my-reviews",
      component: _334b8cde,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___en"
    }, {
      path: "my-wishlist",
      component: _355b0792,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___en"
    }, {
      path: "order-history",
      component: _2b6254c4,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___en"
    }, {
      path: "addresses-details/create",
      component: _42bb76ac,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___en"
    }, {
      path: "/en/reset-password",
      component: _45cf1c19,
      alias: "/customer/account/createPassword",
      name: "reset-password___en"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _05ab6170,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___en"
    }, {
      path: "order-history/:orderId",
      component: _1c78e2d6,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___en"
    }]
  }, {
    path: "/en/gift-cards",
    component: _2d4e1d87,
    name: "gift-cards___en"
  }, {
    path: "/en/GiftCards",
    component: _2d4e1d87,
    name: "GiftCards___en"
  }, {
    path: "/en/global-preview",
    component: _dcf055ce,
    name: "global-preview___en"
  }, {
    path: "/en/GlobalPreview",
    component: _dcf055ce,
    name: "GlobalPreview___en"
  }, {
    path: "/en/Home",
    component: _f03313ca,
    name: "Home___en"
  }, {
    path: "/en/login",
    component: _da659846,
    name: "login___en"
  }, {
    path: "/en/Login",
    component: _da659846,
    name: "Login___en"
  }, {
    path: "/en/maintenance",
    component: _5629c4f2,
    name: "maintenance___en"
  }, {
    path: "/en/Maintenance",
    component: _5629c4f2,
    name: "Maintenance___en"
  }, {
    path: "/en/Page",
    component: _4fc8e86a,
    name: "Page___en"
  }, {
    path: "/en/search",
    component: _3df1afca,
    name: "search___en"
  }, {
    path: "/en/styleguide",
    component: _5251f427,
    name: "styleguide___en"
  }, {
    path: "/en/Styleguide",
    component: _5251f427,
    name: "Styleguide___en"
  }, {
    path: "/th/adyen",
    component: _207d8b4a,
    name: "adyen___th"
  }, {
    path: "/th/adyen-direct",
    component: _40e077a4,
    name: "adyen-direct___th"
  }, {
    path: "/th/cart",
    component: _474ebe2b,
    name: "cart___th"
  }, {
    path: "/th/category-landing",
    component: _9d396862,
    name: "category-landing___th"
  }, {
    path: "/th/checkout",
    component: _ed42c85e,
    name: "checkout___th",
    children: [{
      path: "payment",
      component: _0fb11628,
      name: "payment___th"
    }, {
      path: "shipping",
      component: _221e1408,
      name: "shipping___th"
    }, {
      path: "thank-you",
      component: _4f027cba,
      name: "thank-you___th"
    }]
  }, {
    path: "/th/Cms",
    component: _538cbcbd,
    name: "Cms___th"
  }, {
    path: "/th/customer",
    component: _14d1c9f0,
    meta: {"titleLabel":"My Account"},
    name: "customer___th",
    children: [{
      path: "addresses-details",
      component: _1da6215e,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___th"
    }, {
      path: "my-newsletter",
      component: _ce69b974,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___th"
    }, {
      path: "my-profile",
      component: _39efeb4c,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___th"
    }, {
      path: "my-reviews",
      component: _334b8cde,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___th"
    }, {
      path: "my-wishlist",
      component: _355b0792,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___th"
    }, {
      path: "order-history",
      component: _2b6254c4,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___th"
    }, {
      path: "addresses-details/create",
      component: _42bb76ac,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___th"
    }, {
      path: "/th/reset-password",
      component: _45cf1c19,
      alias: "/customer/account/createPassword",
      name: "reset-password___th"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _05ab6170,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___th"
    }, {
      path: "order-history/:orderId",
      component: _1c78e2d6,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___th"
    }]
  }, {
    path: "/th/gift-cards",
    component: _2d4e1d87,
    name: "gift-cards___th"
  }, {
    path: "/th/GiftCards",
    component: _2d4e1d87,
    name: "GiftCards___th"
  }, {
    path: "/th/global-preview",
    component: _dcf055ce,
    name: "global-preview___th"
  }, {
    path: "/th/GlobalPreview",
    component: _dcf055ce,
    name: "GlobalPreview___th"
  }, {
    path: "/th/Home",
    component: _f03313ca,
    name: "Home___th"
  }, {
    path: "/th/login",
    component: _da659846,
    name: "login___th"
  }, {
    path: "/th/Login",
    component: _da659846,
    name: "Login___th"
  }, {
    path: "/th/maintenance",
    component: _5629c4f2,
    name: "maintenance___th"
  }, {
    path: "/th/Maintenance",
    component: _5629c4f2,
    name: "Maintenance___th"
  }, {
    path: "/th/Page",
    component: _4fc8e86a,
    name: "Page___th"
  }, {
    path: "/th/search",
    component: _3df1afca,
    name: "search___th"
  }, {
    path: "/th/styleguide",
    component: _5251f427,
    name: "styleguide___th"
  }, {
    path: "/th/Styleguide",
    component: _5251f427,
    name: "Styleguide___th"
  }, {
    path: "/en/checkout/secure",
    component: _9ca6e2b0,
    name: "secure-checkout___en"
  }, {
    path: "/en/sso/postauth",
    component: _c81019e8,
    name: "sso-postauth___en"
  }, {
    path: "/th/checkout/secure",
    component: _9ca6e2b0,
    name: "secure-checkout___th"
  }, {
    path: "/th/sso/postauth",
    component: _c81019e8,
    name: "sso-postauth___th"
  }, {
    path: "/en/:slug+",
    component: _4fc8e86a,
    name: "page___en"
  }, {
    path: "/th/:slug+",
    component: _4fc8e86a,
    name: "page___th"
  }],

  parseQuery: function(queryString) {
        return require('qs').parse(queryString);
      },
  stringifyQuery: function(object) {
        var queryString = require('qs').stringify(object);
        return queryString ? '?' + queryString : '';
      },
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
