import { defineStore } from 'pinia';
import type { Cart } from '~/modules/GraphQL/types';

interface CartState {
  cart: Cart;
}

export const useCartStore = defineStore('cart', {
  state: (): CartState => ({
    cart: {
      id: '',
      is_virtual: false,
      is_giftcard_only: false,
      total_quantity: 0,
      shipping_addresses: [],
    },
  }),
});
