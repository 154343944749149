import { Logger } from '~/helpers/logger';
import { VsfContext } from '~/composables/context';
import type { CustomHeaders } from '~/types/core';

export const refreshSsoCustomerTokenCommand = {
  execute: async (
    context: VsfContext,
    ssoToken: string,
    userAgent: string,
    customQuery,
    customHeaders?: CustomHeaders
  ) => {
    Logger.debug('[refreshSsoCustomerTokenCommand]:', {
      ssoToken,
      userAgent,
      customQuery,
      customHeaders,
    });
    const { data, errors } = await context.$magento.api.customQuery({
      query: customQuery,
      queryVariables: {
        ssoToken: ssoToken,
        userAgent: userAgent,
      },
      customHeaders: customHeaders || {},
    });

    Logger.debug('[refreshSsoCustomerTokenCommand]:', { data, errors });

    return {
      data,
      errors,
    };
  },
};
