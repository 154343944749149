import { useContext, ref } from '@nuxtjs/composition-api';
import algoliasearch from 'algoliasearch/lite';
import recommend from '@algolia/recommend';
import { useMagentoConfiguration } from '~/composables';
import type { UseAlgoliaInterface, AlgoliaItem } from './useAlgolia';
import { useCustomerStore } from '~/modules/customer/stores/customer';

export function useAlgolia(): UseAlgoliaInterface {
  const { app } = useContext();

  // Magento config
  const { selectedLocale, selectedCurrency } = useMagentoConfiguration();
  const customerStore = useCustomerStore();

  // API
  const context = useContext();
  const appId = ref(context.$config.algoliaAppId);
  const searchApiKey = ref(context.$config.algoliaApiKey);
  const searchIndex = ref(context.$config.algoliaIndex);
  const algoliaClient = ref(algoliasearch(appId.value, searchApiKey.value));

  // Indexes
  const productIndex = ref(`${searchIndex.value}_${selectedLocale.value}_products`);
  const categoriesIndex = ref(`${searchIndex.value}_${selectedLocale.value}_categories`);
  const pagesIndex = ref(`${searchIndex.value}_${selectedLocale.value}_pages`);

  // User token
  // If we have a customer token, use that, otherwise use the anonymous token
  const customerToken = ref(customerStore.isLoggedIn ? customerStore.user?.email : null);
  // hash customer token
  const md5 = require('md5');
  const hashedCustomerToken = ref(customerToken.value ? md5(customerToken.value) : null);
  // Try and get the anonymous token from the cookie, if not use 'anonymous'
  const anonymousToken = ref(
    context.app.$cookies.get('_ALGOLIA') ? context.app.$cookies.get('_ALGOLIA') : 'anonymous'
  );
  const userToken = ref(hashedCustomerToken.value || anonymousToken.value);

  // Recommended
  const recommendClient = recommend(appId.value, searchApiKey.value);
  const algoliaRelatedProducts = ref([]);
  const algoliaFrequentlyBoughtTogether = ref([]);
  const algoliaTrendingItems = ref([]);
  const loading = ref(false);

  const getRelatedProducts = (objectID: string) => {
    loading.value = true;
    recommendClient
      .getRelatedProducts([
        {
          indexName: productIndex.value,
          objectID: objectID,
          maxRecommendations: 20,
        },
      ])
      .then(({ results }) => {
        // @ts-ignore
        algoliaRelatedProducts.value = algoliaItemMap(results) ?? [];
        loading.value = false;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFrequentlyBoughtTogether = (objectID: string) => {
    loading.value = true;
    recommendClient
      .getFrequentlyBoughtTogether([
        {
          indexName: productIndex.value,
          objectID: objectID,
          maxRecommendations: 20,
        },
      ])
      .then(({ results }) => {
        // @ts-ignore
        algoliaFrequentlyBoughtTogether.value = algoliaItemMap(results) ?? [];
        loading.value = false;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTrendingItems = (facetName: string, facetValue: string) => {
    loading.value = true;
    recommendClient
      .getTrendingItems([
        {
          indexName: productIndex.value,
          threshold: 10,
          facetName: facetName,
          facetValue: facetValue,
        },
      ])
      .then(({ results }) => {
        // @ts-ignore
        algoliaTrendingItems.value = algoliaItemMap(results) ?? [];
        loading.value = false;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Algolia Item Map
  const algoliaItemMap = (products: Array<any>) => {
    return products[0].hits.map((product: AlgoliaItem) => ({
      uid: product.objectID,
      title: product.name,
      image: product.image_url,
      regularPrice: product.price[selectedCurrency.value].default,
      link: productUrl(product.url),
    }));
  };

  // VSF friendy URLs
  const productUrl = (url: string) => {
    const pathname = new URL(url).pathname;
    return `${pathname}`;
  };
  const catgeoryUrl = (url: string) => {
    const pathname = new URL(url).pathname;
    return `${pathname}`;
  };

  // Data transforms
  const transformProductItems = (items: Array<any>) => {
    return items.map((item) => ({
      ...item,
      // get price data from selected currency
      price: item.price[selectedCurrency.value].default,
      // convert url to correct format
      url: productUrl(item.url),
      // convert array to string
      categories_without_path: item.categories_without_path.join(', '),
    }));
  };
  const transformCategoryItems = (items: Array<any>) => {
    return items.map((item) => ({
      ...item,
      // convert url to correct format
      url: catgeoryUrl(item.url),
    }));
  };
  const transformPageItems = (items: Array<any>) => {
    return items.map((item) => ({
      ...item,
      // convert url to correct format
      slug: `/${item.slug}`,
    }));
  };
  const transformPriceFilter = (items: Array<any>) => {
    return items.map((item) => ({
      ...item,
      // get price data from selected currency
      label: app.$fc(item.label),
    }));
  };

  return {
    algoliaClient,
    productIndex,
    categoriesIndex,
    pagesIndex,
    userToken,
    transformProductItems,
    transformCategoryItems,
    transformPageItems,
    transformPriceFilter,
    getRelatedProducts,
    algoliaRelatedProducts,
    getFrequentlyBoughtTogether,
    algoliaFrequentlyBoughtTogether,
    getTrendingItems,
    algoliaTrendingItems,
    productUrl,
    loading,
  };
}

export default useAlgolia;
