import { UseFreshRelevanceInterface } from './useFreshRelevance';
import { useMagentoConfiguration } from '~/composables';
import { Product } from '~/modules/catalog/product/types';
import { Cart } from '~/modules/GraphQL/types';
import { useConfig } from '~/composables';

declare global {
  interface Window {
    $TB: any;
  }
}

// Convert product detail data into FR format
const convertProductToFreshRelevance = (product: Product, currency: string, url: string) => {
  let frProduct = {
    name: product.name,
    id: product.uid,
    sale_price: product.price_range.maximum_price.regular_price.value,
    price: product.price_range.maximum_price.final_price.value,
    sku: product.sku,
    url: url + product.url_key,
    currency: currency,
    image: product.image.url,
    thumbnail: product.thumbnail.url,
    stock: product.stock.stock_qty,
    categories: [],
  };

  if (
    product.price_range.maximum_price.regular_price.value >
    product.price_range.maximum_price.final_price.value
  ) {
    frProduct.price = product.price_range.maximum_price.final_price.value;
  }

  if (product.categories) {
    product.categories.forEach((category) => {
      frProduct.categories.push(category.name);
    });
  }

  return frProduct;
};

const convertCartToFreshRelevance = (cart: Cart, currency: string, url: string) => {
  let frCart = {
    cart_value: cart.prices.grand_total.value,
    cart_quantity: cart.total_quantity,
    cart_currency: currency,
    products: [],
  };

  if (cart.items) {
    cart.items.forEach((item) => {
      let cats = [];
      item.product.categories?.forEach((category) => {
        cats.push(category.name);
      });
      frCart.products.push({
        name: item.product.name,
        id: item.product.uid,
        sku: item.product.sku,
        url: url + item.product.url_key,
        thumbnail: item.product.thumbnail.url,
        image: item.product.image.url,
        sale_price: item.product.price_range.maximum_price.regular_price.value,
        price: item.product.price_range.maximum_price.final_price.value,
        quantity: item.quantity,
        stock: item.configured_variant
          ? item.configured_variant.stock.stock_qty
          : item.product.stock.stock_qty,
        categories: cats,
        options: {
          configurable_options: item.configurable_options,
          personalisation_options: item.personalisation_options,
          sale_price: item.prices.row_total.value / item.quantity,
        },
      });
    });
  }
  return frCart;
};

export function useFreshRelevance(): UseFreshRelevanceInterface {
  const { selectedCurrency } = useMagentoConfiguration();
  const { config } = useConfig();

  const $TB = () => (window as any).$TB;

  const frPageChange = () => {
    if (typeof window.$TB === 'undefined') {
      return;
    }
    $TB().hooks.fireEvent('pageChange', null, { sendBeacon: true });
  };

  const frProductBrowse = (product: Product) => {
    if (typeof window.$TB === 'undefined') {
      return;
    }
    $TB().hooks.fireEvent(
      'productBrowse',
      convertProductToFreshRelevance(product, selectedCurrency.value, config.value.storefront_url),
      {
        sendBeacon: true,
      }
    );
  };

  const frCartUpdate = (cart: Cart) => {
    if (typeof window.$TB === 'undefined') {
      return;
    }
    $TB().hooks.fireEvent(
      'cartUpdate',
      convertCartToFreshRelevance(cart, selectedCurrency.value, config.value.storefront_url),
      {
        sendBeacon: true,
        inPage: true,
      }
    );
  };

  const frPurchaseComplete = (orderNumber: string) => {
    if (typeof window.$TB === 'undefined') {
      return;
    }
    $TB().hooks.fireEvent(
      'purchaseComplete',
      { orderNumber },
      {
        sendBeacon: true,
      }
    );
  };

  const frSetEmail = (email: string) => {
    if (typeof window.$TB === 'undefined') {
      return;
    }
    $TB().setEmail(email);
    $TB().sendBeacon();
  };

  return {
    frPageChange,
    frProductBrowse,
    frCartUpdate,
    frPurchaseComplete,
    frSetEmail,
  };
}
