//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LazyHydrate from 'vue-lazy-hydration';
import {
  useRoute,
  defineComponent,
  computed,
  onErrorCaptured,
  useContext,
  ref,
} from '@nuxtjs/composition-api';
import { useUiState } from '~/composables';
import { useErrorStore } from '~/stores/error';
import { storeToRefs } from 'pinia';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import Theme from '~/components/Theme.vue';

export default defineComponent({
  name: 'DefaultLayout',

  components: {
    LoadWhenVisible,
    LazyHydrate,
    AppHeader: () => import(/* webpackPrefetch: true */ '~/components/AppHeader.vue'),
    CartSidebar: () => import(/* webpackPrefetch: true */ '~/components/CartSidebar.vue'),
    Notification: () => import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
    RecentlyViewed: () =>
      import(/* webpackPrefetch: true */ '~/modules/catalog/product/components/RecentlyViewed.vue'),
    ExitCapture: () => import(/* webpackPrefetch: true */ '~/components/ExitCapture.vue'),
    AppFooter: () => import(/* webpackPrefetch: true */ '~/components/AppFooter.vue'),
    Theme,
  },

  setup() {
    const { app } = useContext();
    const route = useRoute();
    const { isCartSidebarOpen, isHeaderSticky, isLocalisationOpen, toggleCartSidebar } =
      useUiState();

    const error = useErrorStore();
    const { getError } = storeToRefs(error);
    const hasError = computed(() => {
      if (error.getError.statusCode === 200 || error.getError.statusCode === 404) {
        return false;
      } else {
        return true;
      }
    });

    const showLoader = ref(false);

    onErrorCaptured((err) => {
      app.$log.error(err);
    });

    return {
      isCartSidebarOpen,
      toggleCartSidebar,
      isHeaderSticky,
      isLocalisationOpen,
      route,
      getError,
      hasError,
      theme: app.$theme,
      showLoader,
    };
  },
  head() {
    return this.$nuxtI18nHead({ addSeoAttributes: true });
  },
});
