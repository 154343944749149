import { defineStore } from 'pinia';
import shippingTypeEnum from '~/modules/checkout/enums/shippingType';

interface checkoutState {
  shipping: any;
  billing: any;
  availableShippingMethods: Array<any>;
  currentAddressId: number;
  currentBillingAddressId: number;
  shippingType: string;
  sameAsShipping: boolean;
  orderId: string;
  cartId: string;
  default_shipping_address: boolean;
  isEditingShippingAddress: boolean;
  isEditingBillingAddress: boolean;
  isShippingDetailsStepCompleted: boolean;
  isShippingMethodStepCompleted: boolean;
  isBillingDetailsCompleted: boolean;
  clickCollectSet: boolean;
  showCheckoutLoader: boolean;
}

export const useCheckoutStore = defineStore('checkout', {
  state: (): checkoutState => ({
    shipping: null,
    billing: null,
    availableShippingMethods: [],
    currentAddressId: null,
    currentBillingAddressId: null,
    shippingType: shippingTypeEnum.SHIP,
    sameAsShipping: false,
    orderId: null,
    cartId: null,
    default_shipping_address: false,
    isEditingShippingAddress: false,
    isEditingBillingAddress: false,
    isShippingDetailsStepCompleted: false,
    isShippingMethodStepCompleted: false,
    isBillingDetailsCompleted: false,
    clickCollectSet: false,
    showCheckoutLoader: false,
  }),
  actions: {
    setShippingType(payload: string) {
      this.shippingType = payload;
    },
    setSameAsShipping(payload: boolean) {
      this.sameAsShipping = payload;
    },
    setEditingShippingAdress(payload: boolean) {
      this.isEditingShippingAddress = payload;
    },
  },
  persist: {
    enabled: true,
  },
});
