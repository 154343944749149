import { Logger } from '~/helpers/logger';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import { useCartStore } from '~/modules/checkout/stores/cart';
import refreshSsoCustomerTokenQuery from '~/modules/sso/composables/useSso/GraphQL/refreshCustomerSsoToken.gql';
import { refreshSsoCustomerTokenCommand } from '~/modules/sso/composables/useSso/commands/refreshSsoCustomerTokenCommand';
import { useUiNotification } from '~/composables';

export function useSsoCookie() {
  const { send: sendNotification } = useUiNotification();
  const customerStore = useCustomerStore();
  const cartStore = useCartStore();

  // Handle login/logout functions in Middleware
  const ssoCookieNonRedirect = async (context): Promise<void> => {
    Logger.info('sso-cookie-non-redirect');
    const ssoPostAuthPath = context.app.localePath('/sso/postauth');
    const secureCheckoutPath = context.app.localePath('/checkout/secure');
    const apiState = context.$vsf.$magento.config.state;
    const ssoCookie = context.app.$cookies.get('_sa_sso_upid');
    const deviceUserAgent = context.app.$device.userAgent;

    // log customer out
    const cookieLogout = async () => {
      Logger.info('sso-cookie-non-redirect logout start');
      try {
        // remove magento customer token
        await context.app.context.$vsf.$magento.api.revokeCustomerToken({}, {});
        apiState.removeCustomerToken();
        // remove sso related cookies
        apiState.removeRefreshToken();
        apiState.removeSsoSessionToken();
        // remove cart
        apiState.removeCartId();
        cartStore.$patch((state) => {
          state.cart = null;
        });
        // set user as logged out
        customerStore.setIsLoggedIn(false);
        customerStore.user = null;
      } catch (err) {
        context.app.$log.error(err);
        Logger.error('middleware/sso', err);
      } finally {
        Logger.info('sso-cookie-non-redirect logout finish');
        sendNotification({
          id: Symbol('magentoLogout'),
          title: context.app.i18n.t('You have been logged out of your account.') as string,
          type: 'warning',
          icon: 'check',
          persist: false,
          message: '',
        });
      }
    };

    // log customer in
    const cookieLogin = async () => {
      Logger.info('sso-cookie-non-redirect login start');

      try {
        // use sso cookie to get customer token
        const { data, errors } = (await refreshSsoCustomerTokenCommand.execute(
          context.app.$vsf,
          ssoCookie.toString(),
          deviceUserAgent,
          refreshSsoCustomerTokenQuery
        )) as { data: any; errors: any };
        // if fails do not log in
        if (errors) {
          const joinedErrors = errors.map((e) => e.message).join(',');
          Logger.error(joinedErrors);
          // remove sso related cookies
          context.app.$cookies.remove('_sa_sso_upid');
          apiState.removeRefreshToken();
          apiState.removeSsoSessionToken();
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          throw new Error(joinedErrors);
        }
        // set customer asa logged in
        customerStore.setIsLoggedIn(true);
        // set customer token and sso session token
        if (data?.refreshCustomerSsoToken) {
          apiState.setCustomerToken(data.refreshCustomerSsoToken.token);
        }
        apiState.setRefreshToken(true, {
          maxAge: 60 * 45,
        });
        apiState.setSsoSessionToken(ssoCookie);

        // merge existing cart with customer cart
        const currentCartId = apiState.getCartId();
        const cart = await context.app.context.$vsf.$magento.api.customerCart();
        const newCartId = cart.data.customerCart.id;

        try {
          if (newCartId && currentCartId && currentCartId !== newCartId) {
            const { data: dataMergeCart } = await context.app.context.$vsf.$magento.api.mergeCarts({
              sourceCartId: currentCartId,
              destinationCartId: newCartId,
            });
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            cartStore.$patch((state) => {
              state.cart = dataMergeCart.mergeCarts;
            });
            apiState.setCartId(dataMergeCart.mergeCarts.id);
          } else {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            cartStore.$patch((state) => {
              state.cart = cart.data.customerCart;
            });
          }
        } catch {
          // Workaround for Magento 2.4.4 mergeCarts mutation error related with Bundle products
          // It can be removed when Magento 2.4.5 will be release
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          cartStore.$patch((state) => {
            state.cart = cart.data.customerCart;
          });
        }
      } catch (err) {
        context.app.$log.error(err);
        Logger.error('ssoCookieNonRedirect/sso', err);
      } finally {
        Logger.info('sso-cookie-non-redirect login finish');
        sendNotification({
          id: Symbol('magentoLoginSuccess'),
          title: context.app.i18n.t('Successfully logged in.') as string,
          type: 'success',
          icon: 'check',
          persist: false,
          message: '',
        });
      }
    };

    // SSO redirect or cookie page, no need to do anything
    // If we have tokenpayload stop future functions here running - fixes redirect/sso cookie logins from both running
    if (
      context.route.path === ssoPostAuthPath ||
      context.route.path === secureCheckoutPath ||
      context.route.query.tokenpayload
    ) {
      return;
    }

    // Logged in requires
    //  - SSO Cookie ('_sa_sso_upid')
    //  - VSF Customer Cookie ('vsf-customer')]
    //  - SSO Cookie matching SSO session Cookie

    // If we have sso cooke that matches session version and customer token, then we are logged in and need to check if refresh token needs resending
    if (ssoCookie && apiState.getCustomerToken() && ssoCookie === apiState.getSsoSessionToken()) {
      Logger.info('sso-cookie-set-as-logged-in');
      customerStore.setIsLoggedIn(true);
      // if refresh token has expired lets generate a new one by sending query
      if (!apiState.getRefreshToken()) {
        Logger.info('sso-cookie-refresh-token');
        try {
          const { errors } = await refreshSsoCustomerTokenCommand.execute(
            context.app.$vsf,
            ssoCookie.toString(),
            deviceUserAgent,
            refreshSsoCustomerTokenQuery,
            { Authorization: `Bearer ${apiState.getCustomerToken()}` }
          );
          // if we have errors, remove sso cookie and log user out
          if (errors) {
            const joinedErrors = errors.map((e) => e.message).join(',');
            Logger.error(joinedErrors);
            context.app.$cookies.remove('_sa_sso_upid');
            await cookieLogout();
            throw new Error(joinedErrors);
          }
          // if no errors renew refresh cookie
          apiState.setRefreshToken(true, {
            maxAge: 60 * 45,
          });
        } catch (err) {
          context.app.$log.error(err);
          Logger.error('useSsoCookie/cookieLogin', err);
        } finally {
          Logger.debug('useSsoCookie/cookieLogin finally');
        }
      }
      return;
    }

    // if we have both sso cookie and customer cookie, but sso cookie does not match session version, log user out
    if (ssoCookie && apiState.getCustomerToken() && ssoCookie != apiState.getSsoSessionToken()) {
      Logger.info('sso-cookie-sso-cookie-no-match');
      await cookieLogout();
      return;
    }

    // If there is no provider cookie: No, If provider cookie != session provider cookie then do following
    // If we have a 'Customer Token' but no 'SSO cookie', log user out.
    if (!ssoCookie && apiState.getCustomerToken()) {
      Logger.info('sso-cookie-no-sso-cookie-customer-token');
      await cookieLogout();
      return;
    }

    // If user is not logged in
    // if (!customerStore.isLoggedIn) {
    // If we have a 'SSO cookie' but no 'Customer Token', log user in.
    if (ssoCookie && !apiState.getCustomerToken()) {
      Logger.info('sso-cookie-sso-cookie-no-customer-token');
      await cookieLogin();
    }
  };

  return {
    ssoCookieNonRedirect,
  };
}

export default useSsoCookie;
