



















import LazyHydrate from 'vue-lazy-hydration';
import { useRoute, defineComponent, useContext, onErrorCaptured } from '@nuxtjs/composition-api';
import { useUiState } from '~/composables';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';

export default defineComponent({
  name: 'Simple',
  components: {
    LoadWhenVisible,
    LazyHydrate,
    AppFooter: () => import(/* webpackPrefetch: true */ '~/components/AppFooter.vue'),
    SimpleHeader: () => import(/* webpackPrefetch: true */ '~/components/SimpleHeader.vue'),
    Notification: () => import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
  },

  setup() {
    const { app } = useContext();
    const route = useRoute();
    const { isHeaderSticky } = useUiState();

    onErrorCaptured((err) => {
      app.$log.error(err);
    });

    return {
      isHeaderSticky,
      route,
    };
  },
  head() {
    return this.$nuxtI18nHead({ addSeoAttributes: true });
  },
});
