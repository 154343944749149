import locale8aff160a from '../../lang/en_gb.json'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"en","numberFormats":{"en_gb":{"currency":{"style":"currency","currency":"GBP","currencyDisplay":"symbol"}}}},
  vueI18nLoader: false,
  locales: [{"code":"en","file":"en_gb.json","iso":"en-GB","defaultCurrency":"GBP","defaultCountry":"GB"},{"code":"th","file":"th.json","iso":"TH","defaultCurrency":"THB","defaultCountry":"TH"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix",
  lazy: true,
  langDir: "lang/",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "https://shop.lcfc.com",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  country: "GB",
  autoChangeCookie: {"currency":false,"locale":false,"country":false},
  seo: false,
  normalizedLocales: [{"code":"en","file":"en_gb.json","iso":"en-GB","defaultCurrency":"GBP","defaultCountry":"GB"},{"code":"th","file":"th.json","iso":"TH","defaultCurrency":"THB","defaultCountry":"TH"}],
  localeCodes: ["en","th"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: "_",
  5: "g",
  6: "b",
  7: ".",
  8: "j",
  9: "s",
  10: "o",
  11: "n",
  12: "\"",
  13: ":",
  14: "\"",
  15: ".",
  16: ".",
  17: "/",
  18: "l",
  19: "a",
  20: "n",
  21: "g",
  22: "/",
  23: "e",
  24: "n",
  25: "_",
  26: "g",
  27: "b",
  28: ".",
  29: "j",
  30: "s",
  31: "o",
  32: "n",
  33: "\"",
  34: ",",
  35: "\"",
  36: "t",
  37: "h",
  38: ".",
  39: "j",
  40: "s",
  41: "o",
  42: "n",
  43: "\"",
  44: ":",
  45: "\"",
  46: ".",
  47: ".",
  48: "/",
  49: "l",
  50: "a",
  51: "n",
  52: "g",
  53: "/",
  54: "t",
  55: "h",
  56: ".",
  57: "j",
  58: "s",
  59: "o",
  60: "n",
  61: "\"",
  62: "}",
}

export const localeMessages = {
  'en_gb.json': () => Promise.resolve(locale8aff160a),
  'th.json': () => import('../../lang/th.json' /* webpackChunkName: "lang-th.json" */),
}
