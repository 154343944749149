import { defineStore } from 'pinia';
import type { Customer } from '~/modules/GraphQL/types';

interface CustomerState {
  user: Customer | null;
  isLoggedIn: boolean;
  referer: string | null;
}

export const useCustomerStore = defineStore('customer', {
  state: (): CustomerState => ({
    user: null,
    isLoggedIn: false,
    referer: null,
  }),
  actions: {
    setIsLoggedIn(isLoggedIn: boolean) {
      this.isLoggedIn = isLoggedIn;
    },
    setReferer(referer: string) {
      this.referer = referer;
    },
  },
});
