import axios from 'axios';
const serializer = require('pino-std-serializers').err;

export default (context, inject) => {
  const logGatewayCall = (level, message) => {
    // convert error message to std format
    const serialized = serializer(message);
    // send log to api endpoint
    axios
      .post(
        process.client
          ? `${context.$config.middlewareUrl}/pino`
          : `${context.$config.ssrMiddlewareUrl}/pino`,
        {
          level,
          message: serialized,
        }
      )
      .then((res) => {
        console.log(res.data);
      })
      .catch((error) => {
        throw error;
      });
  };

  // entrypoint for logging - for both SSR and CSR
  const logger = {
    warn: (message) => logGatewayCall('warn', message),
    info: (message) => logGatewayCall('info', message),
    error: (message) => logGatewayCall('error', message),
  };

  // Inject $log(msg) in Vue, context and store.
  inject('log', logger);
};
