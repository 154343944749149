import { useContext } from '@nuxtjs/composition-api';
import imageSizes from '~/enums/imageEnums';
import { UseImageInterface } from './useImage';

/**
 * Allows extracting image paths from magento URL.
 *
 * See the {@link UseImageInterface} for a list of methods and values available in this composable.
 * */
export function useImage(): UseImageInterface {
  const context = useContext();
  /**
   * Extract image path from Magento URL.
   *
   * @param fullImageUrl {string | null}
   *
   * @return {string}
   */
  const getMagentoImage = (fullImageUrl: string | null) => {
    if (!fullImageUrl) return '';

    // @TODO why is this here? using fetch requires full url passing to imageProvider
    // @ts-ignore
    //const { imageProvider, magentoBaseUrl } = context.$vsf.$magento.config;
    // if (imageProvider !== 'ipx') {
    //   const url = fullImageUrl.split(`${magentoBaseUrl}`);

    //   const regex = /cache\/(.*?)\//gm;
    //   return url[1].replace(regex, '');
    // }

    return fullImageUrl;
  };

  const getMagentoSwatch = (imagePath: string | null) => {
    if (!imagePath) return '';

    //@ts-ignore
    const { magentoBaseUrl } = context.$vsf.$magento.config;
    const fullImageUrl = magentoBaseUrl + '/media/attribute/swatch' + imagePath;

    return fullImageUrl;
  };

  const getStaticImage = (imagePath: string | null) => {
    if (!imagePath) return '';

    // @ts-ignore
    const { vsfStoreUrl } = context.$vsf.$magento.config;
    const fullImageUrl = vsfStoreUrl + imagePath;

    return fullImageUrl;
  };

  return {
    getMagentoImage,
    getMagentoSwatch,
    getStaticImage,
    imageSizes,
  };
}

export * from './useImage';
export default useImage;
