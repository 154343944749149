/** GraphQL Query that fetches store configuration from the API */
export const StoreConfigQuery = `
  query storeConfig {
    storeConfig {
        adyen_has_holder_name
        adyen_holder_name_required
        adyen_oneclick_card_mode
        base_media_url
        click_collect {
          carrier_code
          enabled
          method_code
          name
          price
          title
        }
        com_gsl_label
        com_gsl_sort_order
        com_gsl_url
        coupon_code_recaptcha
        customer_create_recaptcha
        customer_login_recaptcha
        default_country
        default_display_currency_code
        default_title
        geoip_enabled
        geoip_userAgentWhitelist
        giftcard_enabled
        giftcard_info
        giftcard_recaptcha
        giftwrap_enabled
        giftwrap_sku
        grid_per_page
        header_logo_src
        locale
        logo_alt
        logo_height
        logo_width
        loyalty_balance_enabled
        minimum_password_length
        newsletter_recaptcha
        preorder_enabled
        preorder_label
        preorder_note
        product_review_recaptcha
        required_character_classes_number
        shop_by_player_status
        shop_by_player_route
        size_group_mapping
        size_group_primary_filter
        size_group_secondary_filter
        sso_account_url
        sso_cookie_domain
        sso_dashboard_url
        sso_enabled
        sso_forgot_password_url
        sso_login_url
        sso_logout_url
        sso_register_url
        store_code
        store_collect_address {
          city
          country_code
          firstname
          lastname
          postcode
          region
          street
          telephone
        }
        store_gsl_label
        store_gsl_sort_order
        store_gsl_url
        store_name
        storefront_url
        tickets_gsl_label
        tickets_gsl_sort_order
        tickets_gsl_url
        yotpo_api_url
        yotpo_app_key
        yotpo_bottomline_enabled
        yotpo_bottomline_qna_enabled
        yotpo_category_bottomline_enabled
        yotpo_enabled
        yotpo_mdr_enabled
        yotpo_widget_enabled
        yotpo_widget_url
        adyen_client_key_live
        adyen_client_key_test
        adyen_demo_mode
        adyen_has_holder_name
        adyen_holder_name_required
        adyen_oneclick_card_mode
        adyen_return_path_error
        adyen_title_renderer
        klarna_osm_enable
        klarna_osm_is_playground
        klarna_osm_data_client_id
        klarna_osm_data_custom_local
        klarna_osm_product_enable
        klarna_osm_product_placement
        klarna_osm_product_placement_other
        klarna_osm_cart_enable
        klarna_osm_cart_placement
        klarna_osm_cart_placement_other
        klarna_osm_theme
        allowed_query_params
    }
  }
`;

export default StoreConfigQuery;
