import type { Plugin } from '@nuxt/types';
import type { ApolloQueryResult } from '@apollo/client/core/types';
import { useCustomerStore } from '~/modules/customer/stores/customer';

export const hasGraphqlAuthorizationError = (res: ApolloQueryResult<unknown>) =>
  res?.errors?.some((error) => error?.extensions?.category === 'graphql-authorization') ?? false;

const plugin: Plugin = async ({ $pinia, app, route }) => {
  const customerStore = useCustomerStore($pinia);
  const ssoCookieLoginPath = app.localePath('/sso/cookie');
  const ssoPostAuthPath = app.localePath('/sso/postauth');

  // SSO redirect or cookie page, no need to do anything
  if (route.path === ssoPostAuthPath || route.path === ssoCookieLoginPath) {
    return;
  }
  // @ts-ignore - we need to access the $magento client
  app.$vsf.$magento.client.interceptors.response.use((res) => {
    if (!hasGraphqlAuthorizationError(res.data as ApolloQueryResult<unknown>)) {
      return res;
    }
    customerStore.setIsLoggedIn(false);
    app.$vsf.$magento.config.state.removeCustomerToken();
    app.$vsf.$magento.config.state.removeCartId();
    app.router.push(app.localePath('/'));

    return false;
  });
};

export default plugin;
